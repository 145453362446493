import galleryImg01 from '../../assets/images/Gallery/1.jpg';
import galleryImg02 from '../../assets/images/Gallery/2.jpg';
import galleryImg03 from '../../assets/images/Gallery/3.jpg';
import galleryImg04 from '../../assets/images/Gallery/4.jpg';
import galleryImg05 from '../../assets/images/Gallery/5.jpg';
import galleryImg06 from '../../assets/images/Gallery/6.jpg';
import galleryImg07 from '../../assets/images/Gallery/7.jpg';
import galleryImg08 from '../../assets/images/Gallery/8.jpg';
import galleryImg09 from '../../assets/images/Gallery/9.jpg';
import galleryImg10 from '../../assets/images/Gallery/10.jpg';
import galleryImg11 from '../../assets/images/Gallery/11.jpg';
import galleryImg12 from '../../assets/images/Gallery/12.jpg';
import galleryImg13 from '../../assets/images/Gallery/13.jpg';
import galleryImg14 from '../../assets/images/Gallery/14.jpg';
import galleryImg15 from '../../assets/images/Gallery/15.jpg';
import galleryImg16 from '../../assets/images/Gallery/16.jpg';
import galleryImg17 from '../../assets/images/Gallery/17.jpg';



const galleryImages =[
    galleryImg01,galleryImg02,galleryImg03,galleryImg04,galleryImg05,galleryImg06,galleryImg07,galleryImg08,galleryImg09,galleryImg10,galleryImg11,galleryImg12,galleryImg13,galleryImg14,galleryImg15,galleryImg16,galleryImg17
]
export default galleryImages;